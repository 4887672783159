<template>
	<span>
		<span @click="toggle">
			<slot name="reference" />
		</span>

		<a-modal v-if="isShow" :visible="true" title="Edit Deduction" width="1000px" ok-text="Submit" @cancel="toggle" @ok="submit">
			<a-form-model ref="formRef" :model="formData" :rules="rules" layout="vertical">
				<span class="row-start-center" style="margin-bottom: 10px">
					<div class="switch-label" style="">Upfront Fee</div>
					<a-switch :checked="formData.upfrontSwitch === 1" @change="(v) => onSwitchChange('upfrontSwitch', v)" />
				</span>

				<a-row type="flex" :gutter="20">
					<a-col flex="1">
						<a-form-model-item prop="collectedUpfront" label="Upfront Fee - collected">
							<a-input-number v-model="formData.collectedUpfront" :precision="2" :disabled="formData.upfrontSwitch === 0" />
						</a-form-model-item>
					</a-col>
					<a-col flex="1">
						<a-form-model-item prop="toBeDeducedUpfront" label="Upfront Fee - to be deducted">
							<a-input-number v-model="formData.toBeDeducedUpfront" :precision="2" :disabled="formData.upfrontSwitch === 0" />
						</a-form-model-item>
					</a-col>
					<a-col flex="1">
						<a-form-model-item prop="totalUpfrontFee" label="Total Upfront Fee">
							{{ totalUpfrontFee }}
						</a-form-model-item>
					</a-col>
				</a-row>

				<span v-if="!isFromIPF">
					<span class="row-start-center" style="margin-bottom: 10px">
						<div class="switch-label" style="">Liquidation</div>
						<a-switch :checked="formData.liquidationSwitch === 1" @change="(v) => onSwitchChange('liquidationSwitch', v)" />
					</span>

					<a-row type="flex" :gutter="20">
						<a-col flex="1">
							<a-form-model-item prop="liquidation" label="Liquidation - Amount">
								<a-input-number v-model="formData.liquidation" :precision="2" :disabled="formData.liquidationSwitch === 0" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1">
							<a-form-model-item prop="regNo" label="Liquidation - Loan Registration No">
								<a-input v-model="formData.regNo" :disabled="formData.liquidationSwitch === 0" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1" />
					</a-row>

					<span class="row-start-center" style="margin-bottom: 10px">
						<div class="switch-label" style="">Other Deduction</div>
						<a-switch :checked="formData.otherSwitch === 1" @change="(v) => onSwitchChange('otherSwitch', v)" />
					</span>

					<a-row type="flex" :gutter="20">
						<a-col flex="1">
							<a-form-model-item prop="otherDeduce" label="Deduction Amount">
								<a-input-number v-model="formData.otherDeduce" :precision="2" :disabled="formData.otherSwitch === 0" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1">
							<a-form-model-item prop="description" label="Description">
								<a-input v-model="formData.description" :max-length="100" :disabled="formData.otherSwitch === 0" />
							</a-form-model-item>
						</a-col>
						<a-col flex="1" />
					</a-row>
				</span>
			</a-form-model>
		</a-modal>
	</span>
</template>

<script>
import { Rule, cloneTargetValue } from '@/utils'
import Decimal from 'decimal.js'
import cloneDeep from 'lodash/cloneDeep'

export default {
	props: {
		upfrontReduction: {
			type: Object
		},
		isFromIPF: {
			type: Boolean,
			default: false
		},
		onSubmitSuccess: {
			type: Function
		}
	},
	data() {
		return {
			isShow: false,
			formData: {
				upfrontSwitch: 0, //upfront开关，1开，0关
				collectedUpfront: 0, //已收取的upfront费用
				toBeDeducedUpfront: 0, //待收取的upfront费用
				liquidationSwitch: 0, //上一笔贷款待还金额开关
				liquidation: 0, //上一笔贷款待还金额
				regNo: '', //上一笔贷款车牌号
				otherSwitch: 0, //其他费用开关
				otherDeduce: 0, //其他费用金额
				description: '' //其他费用描述
			}
		}
	},
	computed: {
		rules() {
			return {
				collectedUpfront: this.formData.upfrontSwitch ? [Rule.requiredInput, Rule.inputGreaterThan(0, true)] : [],
				toBeDeducedUpfront: this.formData.upfrontSwitch ? [Rule.requiredInput, Rule.inputGreaterThan(0, true)] : [],
				totalUpfrontFee: this.formData.upfrontSwitch
					? {
							validator: (rule, value, callback) => {
								if (Number(this.totalUpfrontFee) === 0) {
									callback(new Error("Can't be zero."))
								} else {
									callback()
								}
							},
							trigger: 'blur'
					  }
					: {},
				liquidation: this.formData.liquidationSwitch
					? [
							Rule.requiredInput,
							{
								validator: (rule, value, callback) => {
									if (value === 0) {
										callback(new Error("Can't be zero."))
									} else {
										callback()
									}
								},
								trigger: 'blur'
							}
					  ]
					: [],
				regNo: this.formData.liquidationSwitch ? [Rule.requiredInput] : [],
				otherDeduce: this.formData.otherSwitch
					? [
							Rule.requiredInput,
							{
								validator: (rule, value, callback) => {
									if (value === 0) {
										callback(new Error("Can't be zero."))
									} else {
										callback()
									}
								},
								trigger: 'blur'
							}
					  ]
					: [],
				description: this.formData.otherSwitch ? [Rule.requiredInput] : []
			}
		},
		totalUpfrontFee() {
			return Decimal(this.formData.collectedUpfront || 0)
				.add(Decimal(this.formData.toBeDeducedUpfront || 0))
				.toFixed(2)
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow;
			if (this.isShow) {
				cloneTargetValue(this.formData, this.upfrontReduction)
				this.formData.upfrontSwitch = (this.formData.upfrontSwitch ?this.formData.upfrontSwitch: 0)
				this.formData.toBeDeducedUpfront = (this.formData.toBeDeducedUpfront ?this.formData.toBeDeducedUpfront: 0)
				this.formData.collectedUpfront = (this.formData.collectedUpfront ?this.formData.collectedUpfront: 0)
			}
		},
		onSwitchChange(key, value) {
			this.formData[key] = value ? 1 : 0
			switch (key) {
				case 'upfrontSwitch':
					if (!value) {
						this.formData.collectedUpfront = 0
						this.formData.toBeDeducedUpfront = 0
					}
					break
				case 'liquidationSwitch':
					if (!value) {
						this.formData.liquidation = 0
						this.formData.regNo = ''
					}
					break
				case 'otherSwitch':
					if (!value) {
						this.formData.otherDeduce = 0
						this.formData.description = ''
					}
					break
				default:
					break
			}
		},
		async submit() {
			await this.$refs['formRef'].validate()
			const newUpfrontReduction = cloneDeep(this.upfrontReduction)
			for (const k in this.upfrontReduction) {
				if (this.formData.hasOwnProperty(k)) {
					newUpfrontReduction[k] = this.formData[k]
				}
			}
			this.$emit('update:upfrontReduction', newUpfrontReduction)
			this.$emit('onSubmitSuccess', newUpfrontReduction)
			this.toggle()
		}
	}
}
</script>
<style lang="less" scope>
.switch-label {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.85);
	margin-right: 10px;
	font-weight: 600;
}
</style>
